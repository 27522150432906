@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Raw Tea Logo Color */

/*
Tea - #c47038
Bread - #c1ad75
Cup - #f0f0f0
Outline - #505050

*/


:root {
    --color1: #deb633;
    --color2: #e5d306;
    --color3: #ebedd4;
    --color4: rgb(3, 19, 19);
    --colort1: rgba(0, 0, 0, 0.1);
}

body {
    background-color: var(--color4);
    color: var(--color2);
}

h1 {
    color: var(--color3);
    font-size: 5rem;
}

h2 {
    color: var(--color2);
    font-size: 1.25em;
}

h3 {
    color: var(--color1);
    font-size: 1em;
}

.transparent10 {
    background-color: var(--colort1);
}

.icon-size {
    font-size: 22px;
}

html,body{
    overflow-x: hidden;
    height: 100%;
}

.one-line {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

@media screen and (max-width: 768px) {
    .icon-size {
        font-size: 13px;
    }
}